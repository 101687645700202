import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import CustomSelectList from "../common/CustomSelectList";
import TextInput from "../common/TextInput";

import { getOrganization } from "../../api/get/organization";
import { getContactPerson } from "../../api/get/contactPerson";
import { getUser } from "../../api/get/employee";
import { AddVisit } from "../../api/insert/Visit";
import NewVisitAlert from "../NewVisitAlert";
import FileBadge from "../common/FileBadge";
import { isAlphabeticWithouSpecialCharacter } from "../../validations/Validation";
import VisitDuplication from "../messageModal/VisitDuplication";
import { checkTheExistanceOfVisit } from "../../api/get/visit";



// import location  function to get  current location
import {location} from "../../service/location"



const AddVisitModal = ({
  open,
  onClose,
  openInstModal,
  openAddPesonModal,
  isInstModalOpen,
  isContactModalOpen,
  setRefresh ,
  refresh
}) => {
  const fileRef = useRef();
  const [visitdata, setVisitData] = useState({
    institution: "",
    visitDateTime: "",
    purpose: "",
    contactPersonId: "",
    additionalNotes: "",
    employeeId: "",
    visitType: 1,
  });
  const [openAlert, setOpenAlert] = useState(false);
  const [file, setFile] = useState([]);
  const [openvistDuplicateModal , setOpenvisitDuplicateModal] = useState(false);

  // function to handle the textfield input
  const handleChangeText = (e) => {
    const { name, value } = e.target;
    setInvalidMessages({...invalidMessages,[name]:''})
    console.log(name, "value", value);
    setVisitData({ ...visitdata, [name]: value });
  };

  let [fileIndex, setFileIndex] = useState(1);

  const handleFileUpload = async (e) => {
    const { name, value } = e.target;
    console.log("name", name, "value", value, "file", file);
    const formData = new FormData();
    formData.set("file", value);

    var singleFile = e.target.files[0];
    console.log("sample file", singleFile);
    const fileSizeLimit = Number(process.env.REACT_APP_FILE_SIZE) * 1024 * 1024;
    if (singleFile !== undefined) {
      if (singleFile && singleFile.size > fileSizeLimit) {
        alert(`File size exceeds ${process.env.REACT_APP_FILE_SIZE}MB limit`);
      } else {
        if (file[file.length - 1] === undefined) singleFile.id = 1;
        else singleFile.id = file[file.length - 1].id + 1;

        console.log("file :", file[file.length - 1]);
        var data = file.concat([singleFile]);
        setFileIndex(fileIndex++);
        console.log("count :", fileIndex);
        console.log(file);
        setFile(data);
      }
    }
    e.target.value = null;
  };

  function closePopUp() {
    setInvalidMessages({
      institution: null,
      visitDateTime: null,
      purpose: null,
      contactPersonId: null,
      additionalNotes: null,
      employeeId: null,
      visitType: 1,
    });
    onClose();
  }
  const removeObjectById = (id) => {
    console.log("Removing file with id:", id);

    // Create a new array excluding the file with the given id
    const newArray = file.filter((item) => item.id !== id);

    // Update the state with the new array
    setFile(newArray);

    // Log the updated array
    console.log(newArray);
  };
  const [enableAddContactPerson, setEnableAddContactPerson] = useState(true);

  const organizationId = useRef(""); 
  const instRef = useRef(null); // refference variable for select institution
  const contactPersonRef = useRef(null); // refference variable for select contact person
  const employeeRef = useRef(null); // refference variable for select employee
  const [messageOfDuplicateVisit , setMessageOfDuplicateVisit] = useState('');
  const [canProceed , setCanProceed] = useState(false);


  // addding new visits
  const handleaddNewVist = async () => {
    
    const validatePurpose = isAlphabeticWithouSpecialCharacter(visitdata.purpose);
    const validateNote = isAlphabeticWithouSpecialCharacter(visitdata.additionalNotes);
    let tempInvalidMessages = {};

    // Iterate over each key in visitdata
    Object.keys(visitdata).forEach((key) => {
      // Check for invalid data
      if (
        visitdata[key] === "" ||
        visitdata[key] === undefined ||
        visitdata[key] === null
      ) {
        // Collect invalid message for the current key

        if (key === "institution") {
          tempInvalidMessages[key] = "Please select a institution";
        } else if (key === "employeeId") {
          tempInvalidMessages[key] = "Please pick Employee ";
        } else if (key === "visitDateTime") {
          tempInvalidMessages[key] = "Please pick a date";
        } else if (key === "purpose") {
          tempInvalidMessages[key] = "Please enter  purpose of visit";
        } else if (key === "contactPersonId") {
          tempInvalidMessages[key] = "Please select contact person";
        } else if (key === "additionalNotes") {
          tempInvalidMessages[key] =
            "Please enter  purpose of Additional Notes";
        } else {
          tempInvalidMessages[key] =
            "Invalid data please enter in valid format";
        }

        // Log the invalid key-value pair (optional)
        console.log(key, ":", visitdata[key]);
      }

      // Log each value (optional)
      console.log("value", visitdata[key]);
    });
   
    // Update state with collected invalid messages
    setInvalidMessages(tempInvalidMessages);

    if(visitdata.institution ==='' || visitdata.employeeId === '' || visitdata.contactPersonId === '' || visitdata.visitDateTime === '' || visitdata.purpose ==='' || visitdata.additionalNotes == ''){

    }
    else if(visitdata.purpose!=='' && validatePurpose === false){
      setInvalidMessages({...invalidMessages,'purpose':'Special characters are not allowed'})
    }
    else if(visitdata.additionalNotes!=='' && validateNote === false){
      setInvalidMessages({...invalidMessages,'additionalNotes':'Special characters are not allowed'})
    }
    else{
      const checkVisit = await checkTheExistanceOfVisit(visitdata.employeeId,visitdata.contactPersonId,visitdata.visitDateTime);
      if(checkVisit.data){
        setRefresh(!refresh)
        const {openPopUp,message,canProceed} = checkVisit.data;
       setOpenvisitDuplicateModal(openPopUp);
       setMessageOfDuplicateVisit(message);
       setCanProceed(canProceed);
       if(openPopUp === false){
          addVisitData();
       }
      }
   
    }
  };


  // function for handling the selection of the list
  const handleListSelection = (e) => {
    const { name, value } = e.target;
    setInvalidMessages({...invalidMessages,[name]:''});
    if (name === "institution") {
      organizationId.current = value;
      contactPersonRef.current.clearValue();
      setEnableAddContactPerson(false);
      getContactPersonFunction(value);
      setVisitData({ ...visitdata, [name]: value , 'contactPersonId':'' });
    }
    else {
      setVisitData({ ...visitdata, [name]: value });
    }
  };


  const addVisitData=async()=>{
    const formData = new FormData();
    file.forEach((item) => {
      formData.append("file", item);
    });

    Object.keys(visitdata).forEach((key) => {
      formData.set(key, visitdata[key]);
    });
    const {hasAccess ,  longitude , latitude}= await location();
    if(hasAccess) {console.log("longitude : ", longitude.toString() + "latitude :", latitude.toString())
      formData.set("hasLocationAccess" , hasAccess);
      formData.set("longitude", longitude);
      formData.set("latitude", latitude)
    }else {
      formData.set("hasLocationAccess" , hasAccess);
    }
    
    const newvisit = await AddVisit(formData);
    console.info(newvisit)
    if (newvisit.data !== undefined)
      if (newvisit.data.success) {
        setOpenAlert(true);
        onClose();
        setVisitData({
          institution: "",
          visitDateTime: "",
          purpose: "",
          contactPersonId: "",
          additionalNotes: "",
          employeeId: "",
          visitType: 1,
        });
        setFile([]);
        var inputs = document.getElementsByClassName("peer");
  
        instRef.current.clearValue();
        contactPersonRef.current.clearValue();
        employeeRef.current.clearValue();
        document
          .querySelectorAll(".peer")
          .forEach((input) => (input.value = ""));
        document
          .querySelectorAll(".basic-single")
          .forEach((input) => (input.value = ""));
        console.log(instRef)
      }
  }
// add the visit by forcefully 
const handlePreceedToAddVisit=()=>{
  setOpenvisitDuplicateModal(false);
  addVisitData();

}
  const [contactPerson, setContactPerson] = useState([]);

  async function getContactPersonFunction(orgId) {
    try {
      const contactPersonsResponse = [];
      const response = await getContactPerson(orgId);

      if (response.length === 0) {
        contactPersonsResponse.push({ "": "select" });
        setContactPerson([]);
      }

      response.forEach((item) => {
        var obj = {};
        obj[item._id] = item.name + " (" + item.designation + ")";
        contactPersonsResponse.push(obj);
        setContactPerson(contactPersonsResponse);
      });
    } catch (error) {
      console.log(error);
    }
  }
  const [organizations, setOrganizations] = useState([]);
  const [employees, setEmployee] = useState([]);

  useEffect(() => {
    async function getOrg() {
      const response = await getOrganization();
      var orgs = [];
      response.data.organizations.forEach((item) => {
        var obj = {};
        obj[item._id] = item.organizationName + " " + item.place + " ["+ item.district +"]";
        orgs.push(obj);
      });
      setOrganizations(orgs);

      async function getEmployeesFromApi() {
        const response = await getUser();
        const emp = [];
        response.data.data.forEach((item) => {
          var obj = [];
          obj[item._id] = item.userId.fullName;
          emp.push(obj);
        });
        setEmployee(emp);
      }
      getEmployeesFromApi();
    }

    getOrg();
  }, []);
  const inputRef = useRef(null);
  useEffect(() => {
    async function getOrg() {
      const response = await getOrganization();
      var orgs = [];
      response.data.organizations.forEach((item) => {
        var obj = {};
        obj[item._id] = item.organizationName + " " + item.place;
        orgs.push(obj);
      });
      setOrganizations(orgs);

      async function getEmployeesFromApi() {
        const response = await getUser();
        const emp = [];
        response.data.data.forEach((item) => {
          var obj = [];
          obj[item._id] = item.userId.fullName;
          emp.push(obj);
        });
        setEmployee(emp);
      }
      getEmployeesFromApi();
    }

    getOrg();
  }, [isInstModalOpen, isContactModalOpen]);

  const [invalidMessages, setInvalidMessages] = useState({
    institution: null,
    visitDateTime: null,
    purpose: null,
    contactPersonId: null,
    additionalNotes: null,
    employeeId: null,
    visitType: 1,
  });

// close the warhing popup of duplication entry
const handleCloseVisitWarningModal =()=>{
  setOpenvisitDuplicateModal(false);
}
  return (
    <>
      
      <NewVisitAlert isOpen={openAlert} setOpen={setOpenAlert} />

      <div
        id="static-modal"
        data-modal-backdrop="static"
        tabIndex="-1"
        aria-hidden={!open}
        className={`overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full bg-gray-800 bg-opacity-50 md:inset-0 h-full ${
          open ? "flex" : "hidden"
        }`}
      >
         
        <div className="relative p-4 w-full max-w-2xl max-h-full">
          {/* Modal content */}
          <div className="relative bg-white rounded-lg shadow-2xl">
            {/* Modal header */}
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900">
                Add Visits
              </h3>
              <button
                type="button"
                className="text-gray-700 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-400 dark:hover:text-white"
                onClick={closePopUp}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* Modal body */}
            <div className="flex flex-col px-2 gap-2 mt-3">
              <p className="text-lg ml-2">
                Select Institution
                <span className="text-red-500">*</span>
              </p>

              <div className="w-full flex flex-row justify-between gap-5 px-4 items-center">
                <CustomSelectList
                  // value={'6654286a5948edee36ed6260'}
                  refv={instRef}
                  items={organizations}
                  name={"institution"}
                  onChange={handleListSelection}
                  invalidMessage={invalidMessages.institution}
                />
                <button
                  onClick={openInstModal}
                  className="bg-[#2a6fe7] hover:bg-[#1864e8] active:bg-[#1864e8] px-2 py-2 rounded-md text-white font-semibold w-[70px]"
                >
                  Add
                </button>
              </div>
            </div>

            <div className="flex flex-col px-2 gap-2 mt-3">
              <p className="text-lg ml-2">
                Select Employee <span className="text-red-500">*</span>
              </p>
              <div className="w-full flex flex-row justify-between gap-5 px-4 items-center">
                <CustomSelectList
                  refv={employeeRef}
                  items={employees}
                  name={"employeeId"}
                  onChange={handleListSelection}
                  invalidMessage={invalidMessages.employeeId}
                />
              </div>
            </div>

            <div className="flex flex-col gap-2 px-4 mt-3">
              <p className="text-lg ml-2">
                Date <span className="text-red-500">*</span>
              </p>
              <TextInput
                placeholder={"Date"}
                label={"Date"}
                onChange={handleChangeText}
                name={"visitDateTime"}
                type={"datetime-local"}
                invalidMessage={invalidMessages.visitDateTime}
              />
            </div>

            <div className="flex flex-col gap-2 px-4 mt-3">
              <p className="text-lg ml-2">
                Purpose of Visit <span className="text-red-500">*</span>
              </p>
              <TextInput
                placeholder={"Purpose of Visit"}
                label={"Purpose of Visit"}
                type={"text"}
                onChange={handleChangeText}
                name={"purpose"}
                invalidMessage={invalidMessages.purpose}
              />
            </div>

            <div className="flex flex-col px-2 gap-2 mt-3">
              <p className="text-lg ml-2">
                Contact Person <span className="text-red-500">*</span>
              </p>
              <div className="w-full flex flex-row justify-between gap-5 px-4 items-center">
                <CustomSelectList
                  refv={contactPersonRef}
                  items={contactPerson}
                  name={"contactPersonId"}
                  onChange={handleListSelection}
                  invalidMessage={invalidMessages.contactPersonId}
                  value={visitdata.contactPersonId}
                  onFocus={() => {
                    getContactPersonFunction(organizationId.current);
                  }}
                />
                <button
                  disabled={enableAddContactPerson}
                  onClick={() => {
                    openAddPesonModal(organizationId.current);
                  }}
                  className={`${
                    enableAddContactPerson === true
                      ? " bg-blue-400"
                      : "bg-[#2a6fe7]"
                  }  hover:${
                    !enableAddContactPerson && "bg-[#1563e9]"
                  } active:${
                    !enableAddContactPerson && "bg-[#1563e9]"
                  } px-2 py-2 rounded-md text-white font-semibold w-[70px]`}
                >
                  Add
                </button>
              </div>
            </div>

            <div className="flex flex-col gap-2 px-4 mt-3 mb-4">
              <p className="text-lg ml-2">
                Adding Note <span className="text-red-500">*</span>
              </p>
              <TextInput
                placeholder={"Adding Note"}
                label={"Adding Note"}
                type={"text"}
                onChange={handleChangeText}
                name={"additionalNotes"}
                invalidMessage={invalidMessages.additionalNotes}
              />
              {/* <Textarea/> */}
            </div>

            <div className="flex flex-col gap-2 px-4 mt-3 mb-4">
              <p className="text-lg ml-2">Discussion Topic</p>
              <TextInput
                placeholder={"Discussion Topic"}
                label={"Discussion Topic"}
                type={"text"}
                onChange={handleChangeText}
                name={"discussionTopic"}
                invalidMessage={invalidMessages.discussionTopic}
              />
            </div>

            <div class="bg-white px-4 flex items-center flex-wrap">
              {file.length !== 0 &&
                file.map((item) => (
                  <FileBadge
                    name={item.name}
                    filename={item.name}
                    handleRemove={() => {
                      removeObjectById(item.id);
                    }}
                  />
                ))}
            </div>

            <div className="flex flex-col gap-2 px-4 mt-3 mb-4">
              <p className="text-lg ml-2">Upload Documents</p>
              <div className="flex justify-end gap-1  items-center mb-[-5px]">
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 24 24"
                  fill="blue"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 19.2C7.68 19.2 4.8 16.32 4.8 12C4.8 7.68 7.68 4.8 12 4.8C16.32 4.8 19.2 7.68 19.2 12C19.2 16.32 16.32 19.2 12 19.2ZM11 10H13V18H11V10ZM11 7H13V9H11V7Z" />
                </svg>
                <p className="text-[12px] text-[#3f4cff]">
                  Upload a file with maximum size of 2 MB
                </p>
              </div>
              {/* <TextInput
                placeholder={"Doc Upload"}
                label={"Doc Upload"}
                type={"file"}
                name={"file"}
                onChange={handleFileUpload}
                refv={fileRef}
              /> */}
              <div className=" relative">
                  <label className=" absolute top-3 left-3  bg-blue-500 hover:bg-blue-600 py-1 px-4 text-sm rounded-lg text-white font-semibold">
                      <input label={"Doc Upload"}
                        name={"file"}
                        onChange={handleFileUpload}
                        refv={fileRef}  type="file" id="formId" hidden 
                      />
                      Browse
                  </label>
                  <input
                      placeholder="Upload File.."
                      className="text-md block px-24 py-1 rounded-lg w-full border-2 border-gray-300 placeholder-gray-700 shadow-md focus:outline-none h-14"
                      disabled
                    />       
              </div>
            </div>

            {/* bottom buttuns  */}
            <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                onClick={handleaddNewVist}
              >
                Submit
              </button>
              <button
                type="button"
                className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <VisitDuplication open={openvistDuplicateModal} onClose={()=>{setOpenvisitDuplicateModal(false)}} message={messageOfDuplicateVisit} canProceed={canProceed} handleSubmit={handlePreceedToAddVisit} />
    </>
  );
};

AddVisitModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddVisitModal;
