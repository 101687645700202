import React,{useEffect, useRef, useState} from "react";
import PasswordInput from "../components/common/PasswordInput";
import TextInputNormal from "../components/common/TextInputNormal";
import UserRegModal from "./forms/UserRegFormModal";
import { ValidateEmail } from "../validations/Validation";
import NotificationToast from "../components/NotificationComponent/NotificatioToast";

// Toast notification 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { userLogin } from "../api/auth/Login"; //login api function

import {useNavigate} from 'react-router-dom';
import PasswordReset from "../components/password/PasswordresetModal";
import { passwordResetLinkTrigger } from "../api/auth/Password";
import MessageSuccess from "../components/messageModal/MessageSuccess";


const LandingPage = () => {
  const navigate = useNavigate(); // navigations are handled by the navigate variable
  const[openRegFormModal , setOpenRegModal] = useState(false); //variable for controle the user registration modal
  const [errorMessage , setErrorMessage] = useState({ userId:'', password:''}); // Variable for storing error messages
  const [userCredentials , setUserCredentials] = useState({
    userId:'',
    password:''
  })
const [openPasswordReset , setOpenPassworsdReset] = useState(false);
const [openMessage , setOpenMessage] = useState(false);
const buttonRef = useRef(null);

  const handleOpenRegModal =()=>{
    // setOpenRegModal(true);
    // setOpenMessage(true);
  }
  const handleCloseRegModal =()=>{
    setOpenRegModal(false);
  }

  // handle userId 
  const handleUserInput=async(e)=>{
    setErrorMessage({userId:'',password:''})
    const {name , value} = e.target;
    console.log(name , value);
    setUserCredentials({...userCredentials,[name]:value})
  }

  // let validateEmail = await ValidateEmail(value);
  //   if(validateEmail === false){
  //     setErrorMessage('Invalid Email');
  //   }
  //   else{
  //     setErrorMessage('');
  //   }



  // function for handling user authentication
 const handleSignIn = async()=>{

    console.log(userCredentials);
    let userId = userCredentials.userId;
    let password =  userCredentials.password;
    if(userId === '' && password === ''){
      setErrorMessage({userId:"Please enter the user id",password:"Please enter the password"})
    }
    else if(userId === ''){
      setErrorMessage({userId:"Please enter the user id"});
    }
    else if(password === ''){
      setErrorMessage({password:"Please enter the password"});
    }
    else{
      setErrorMessage({userId:'',password:''})
    const userauth = await userLogin(userId,password);
    if(userauth.data){
        let loginCredential = userauth.data;
        toast.success(loginCredential.message);
        localStorage.setItem('token',loginCredential.token)
        navigate('/homepage');

    }
    else{
      console.log("errror",userauth.response.data)
      let autherror = userauth.response.data;
       toast.error(autherror.message);
    }
  }
 }
// open the password reset modal
const handleOpenPasswordReset=()=>{
  setOpenPassworsdReset(true);
}
//  close the password reset modal
const handleClosePasswordReset =()=>{
  setOpenPassworsdReset(false);
}

// handle close the message
const handleCloseMessage=()=>{
  setOpenMessage(false);
}

useEffect(()=>{
  const handleKeyDown =(event)=>{
    if(event.key === 'Enter'){
      buttonRef.current.click();
    }
  };
  window.addEventListener('keydown', handleKeyDown);
  return () => {
    window.removeEventListener('keydown', handleKeyDown);
  };
},[])

  return (
    <div className="flex sm:flex-row sm:items-start justify-center flex-col items-center max-w-screen h-auto sm:min-h-screen  bg-white  sm:bg-[#8addf9] p-0 sm:p-5 md:p-10 xl:p-14">
      <div className="flex w-full flex-col sm:flex-row justify-center sm:p-5 md:p-10 bg-white p-3 rounded-xl">
        

        <div className="hidden sm:flex w-full justify-between flex-col gap-1 sm:bg-[#32b1dc] p-5 md:p-10 rounded-2xl md:rounded-3xl">
          
          <div className="xl:w-3/4 w-full">
          <h2 className="xl:text-5xl lg:text-4xl  sm:text-3xl text-[25px] font-sans font-bold tracking-wider text-[#32b1dc] sm:text-white">
            Welcome To
          </h2>
          <h2 className="xl:text-5xl lg:text-4xl  text-[25px] sm:text-3xl font-sans font-bold sm:mt-0 md:mt-3 tracking-wider sm:text-white">
            Visit App
          </h2>
            <p className="text-[15px] sm:text-[20px] text-justify  font-sans tracking-wider leading-8 mt-5 sm:mt-10">
              Streamline your visits with our Visit Tracking App. Effortlessly
              record, organize, and analyze your appointments on-the-go. Stay
              organized, stay productive!
            </p>
          </div>
          <div className="flex justify-center sm:mt-10 mt-3 ml-10 h-auto w-3/4 sm:full xl:w-2/4">
            <img
              src={require("../assets/frame.svg").default}
              alt=""
              className="object-cover w-full h-full"
            />
          </div>
        </div>

        {/* login box */}
        <div className="flex flex-col justify-strt w-full sm:w-3/4 lg:w-3/5 xl:w-2/5 sm:mt-10 xl:mr-10 sm:pl-10 md:mt-0">
              {/* logo icon */}
            <div className="w-[150px] md:w-2/3 md:mt-5 h-auto  sm:block">
                <img
                    src={require("../assets/ict-logo.svg").default}
                    alt=""
                    className="object-contain w-full"
                />
            </div>
            <h2 className="md:text-3xl sm:hidden text-4xl sm:mt-10  mt-10 text-[#32B1DC] font-extrabold">Visit App</h2>
          <h2 className="md:text-3xl sm:text-2xl text-[20px] sm:mt-10  mt-5 text-[#2A2A2A] font-bold">Welcome Back!</h2>
         
          {/* normal signup box */}
          <div className="flex flex-col sm:mt-5">
            {/* <h2 className='text-2xl text-black font-bold'>SignIn</h2> */}
            {/* <TextInput/> */}
            <div className="">
              <p className="mt-2 font-sans text-15px] sm:text-[20px] font-medium">
                User Id
              </p>
              <TextInputNormal placeholder={'User Id'} name={'userId'} onChange={handleUserInput} />
                <p className="text-[#f72743] ml-2 font-serif text-[13px] ">{errorMessage.userId}</p>
            </div>
           <div>
              <p className="mt-2 font-sans text-[px] sm:text-[20px] font-medium">
                Password
              </p>
              <PasswordInput placeholder={'Password'} name={'password'} onChange={handleUserInput} />
              <p className="text-[#f72743] ml-2 font-serif text-[13px] ">{errorMessage.password}</p>
           </div>


            <div className="flex justify-end pt-2">
                <p onClick={handleOpenPasswordReset} className="text-[15px] text-[#32b1dc] hover:opacity-65 active:underline">Forgotpassword?</p>
            </div>
            <div className="w-full flex justify-center">
              <button ref={buttonRef} onClick={handleSignIn} class="bg-[#32b1dc] w-2/3 rounded-xl hover:bg-[#11a5d7] active:opacity-70 text-white font-bold py-2 px-4 mt-10 h-14 text-[20px] cursor-pointer">
                Sign In
              </button>
            </div>

            {/* google signup box bottom
            <div className='w-full flex justify-center sm:mt-5 items-center flex-col' onClick={()=>{document.location.href="/api/authenticate/google/signup/Employee"}}>
                <p className="text-[#838282] text-[12px] sm:text-[15px] font-[500] mt-2">Or Continue with Google</p>
                <div className="flex justify-center items-center w-2/3 border-2 border-[#32b1dc] h-auto pt-2 pb-2 mt-2 sm:mt-2.5  active:bg-gray-200 cursor-pointer ">
                    <div className="h-[30px] w-[30px] flex items-center box-border overflow-hidden">
                    <img
                        src={require("../assets/google.png")}
                        alt=""
                        className="object-contain w-full"
                    />
                    </div>
                    <h2 className="text-md sm:text-[10px] md:text-[12px] lg:text-md  ml-3 text-[#32b1dc] font-semibold " >
                    Login With Google
                    </h2>
                </div>
            </div>
            {/* sign up text */}
            {/* <div className="w-full flex justify-center text-[#32b1dc] mt-0 pt-5 text-[12px] sm:text-[15px]">
                <p>Don't have an account? <span onClick={handleOpenRegModal} className="font-bold cursor-pointer hover:text-[#0face1] active:opacity-80">SignUp</span></p>
            </div>  */}
          </div>
        </div>
      </div>
      {/* user Registration Modal */}
      <UserRegModal open={openRegFormModal} onClose={handleCloseRegModal} />
      {/* Password Reset Modal */}
      <PasswordReset open={openPasswordReset} onClose={handleClosePasswordReset} openNotification={()=>{setOpenMessage(true)}} />
      <ToastContainer 
         position='top-right'
         autoClose={3000}    
      />
      <MessageSuccess open={openMessage} onClose={handleCloseMessage} />
    </div>
  );
};

export default LandingPage;
